var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "question-review-container"
  }, [_c("section", {
    staticClass: "question-review-top"
  }, [_c("a-form-model", {
    attrs: {
      layout: "inline",
      "label-align": "left",
      form: _vm.queryParams
    }
  }, [_c("a-form-model-item", [_c("a-input", {
    attrs: {
      placeholder: "请输入题干"
    },
    model: {
      value: _vm.queryParams.question,
      callback: function callback($$v) {
        _vm.$set(_vm.queryParams, "question", $$v);
      },
      expression: "queryParams.question"
    }
  })], 1), _c("a-form-model-item", [_c("a-range-picker", {
    attrs: {
      "show-time": "",
      allowClear: "",
      valueFormat: "YYYY-MM-DD HH:mm:ss",
      placeholder: ["申请开始", "申请结束"]
    },
    model: {
      value: _vm.queryParams.applytime,
      callback: function callback($$v) {
        _vm.$set(_vm.queryParams, "applytime", $$v);
      },
      expression: "queryParams.applytime"
    }
  })], 1), _c("a-form-model-item", [_c("a-select", {
    attrs: {
      allowClear: "",
      placeholder: "请选择状态"
    },
    model: {
      value: _vm.queryParams.auditstatus,
      callback: function callback($$v) {
        _vm.$set(_vm.queryParams, "auditstatus", $$v);
      },
      expression: "queryParams.auditstatus"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 0
    }
  }, [_vm._v(" 待审核 ")]), _c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 审核通过 ")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 审核拒绝 ")])], 1)], 1), _c("a-form-model-item", [_c("a-button", {
    attrs: {
      type: "primary",
      "html-type": "submit"
    },
    on: {
      click: _vm.handleSearch
    }
  }, [_vm._v(" 查询 ")]), _c("a-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "html-type": "reset"
    },
    on: {
      click: _vm.handleReset
    }
  }, [_vm._v(" 重置 ")])], 1)], 1)], 1), _c("section", {
    staticClass: "question-review-main"
  }, [_c("a-table", {
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.dataSource,
      pagination: false,
      loading: _vm.loading,
      rowKey: "id"
    },
    scopedSlots: _vm._u([{
      key: "applytime",
      fn: function fn(applytime) {
        return [_c("span", [_vm._v(_vm._s(_vm.formatData(applytime)))])];
      }
    }, {
      key: "auditstatus",
      fn: function fn(auditstatus) {
        return [auditstatus == 0 ? _c("span", [_vm._v("待审核")]) : auditstatus == 1 ? _c("span", [_vm._v("审核通过")]) : auditstatus == 2 ? _c("span", [_vm._v("审核拒绝")]) : _vm._e()];
      }
    }, {
      key: "action",
      fn: function fn(item) {
        return [_c("div", {
          staticClass: "table-actions"
        }, [_c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.handleClick(item, true);
            }
          }
        }, [_vm._v("查看")]), item.auditstatus == 0 ? _c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.handleClick(item, false);
            }
          }
        }, [_vm._v("审核")]) : _vm._e()], 1)];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.queryParams.page,
      size: _vm.queryParams.size,
      onPageSizeChange: _vm.onPageChange,
      onShowSizeChange: _vm.onPageChange
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.queryParams, "page", $event);
      },
      "update:size": function updateSize($event) {
        return _vm.$set(_vm.queryParams, "size", $event);
      }
    }
  })], 1), _c("CreateQuizForm", {
    attrs: {
      title: "申请审核",
      visible: _vm.visible,
      value: _vm.formData,
      loading: _vm.loading2,
      onConfirm: _vm.onSubmit,
      onCancel: _vm.onCancel,
      checkChange: false,
      cancelButtonProps: {
        props: {
          type: "danger",
          disabled: _vm.confirmLoading
        }
      },
      okButtonProps: {
        props: {
          disabled: _vm.confirmLoading
        }
      },
      "cancel-text": "拒绝",
      "ok-text": "通过",
      closable: "",
      showDifficultyLevel: "",
      isedit: !_vm.ispreview,
      footer: _vm.ispreview ? null : undefined
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };